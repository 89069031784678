import { template as template_bd623799f7204d3b8b51d1b11e69928e } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_bd623799f7204d3b8b51d1b11e69928e(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
