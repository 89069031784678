import { template as template_3f64d58011d844fc8f9c71f6f4abb8a0 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_3f64d58011d844fc8f9c71f6f4abb8a0(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
