import { template as template_7c3b7cfb4d1c484b84c73416079e179a } from "@ember/template-compiler";
import icon from "discourse/helpers/d-icon";
const InputTip = template_7c3b7cfb4d1c484b84c73416079e179a(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
