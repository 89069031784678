import { template as template_5cb9ef4f94ea4b51ace338712cbc1e9e } from "@ember/template-compiler";
const FKLabel = template_5cb9ef4f94ea4b51ace338712cbc1e9e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
